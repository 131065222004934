@font-face {
    font-family: 'Gordita Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gordita Regular'), url('https://www.qenta.app/wp-content/themes/saasland-child/font/Gordita Regular.woff') format('woff');
}

@font-face {
    font-family: 'Gordita Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gordita Regular'), url('https://www.qenta.app/wp-content/themes/saasland-child/font/Gordita Regular.woff') format('woff');
}

@font-face {
    font-family: 'Gordita Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gordita Light'), url('https://www.qenta.app/wp-content/themes/saasland-child/font/Gordita Light.woff') format('woff');
}

@font-face {
    font-family: 'Gordita Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gordita Bold'), url('https://www.qenta.app/wp-content/themes/saasland-child/font/Gordita Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gordita Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gordita Medium'), url('https://www.qenta.app/wp-content/themes/saasland-child/font/Gordita Medium.woff') format('woff');
}